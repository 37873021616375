import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import NotesIcon from '@material-ui/icons/Notes';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  ${({ theme, seen, size }) => `
    border-radius: 0;
    width: ${size === 4 ? '17.375rem' : size === 5 ? '28rem' : '45rem'};
    height: ${size === 4 ? '12.5rem' : size === 5 ? '20rem' : '32rem'};
    background-color: ${theme.modeColors.cardBackground};
    ${
      seen === 'true'
        ? `
      border: 0;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    `
        : `
      border: 2px solid #f46b1e;
      box-shadow: none;
    `
    }

    .actionArea {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      &:hover .MuiCardActionArea-focusHighlight {
        opacity: 1;
      }
    }

    .cardMedia {
      height: ${size === 4 ? '9.875rem' : size === 5 ? '17.375rem' : '29.375rem'};
    }

    .MuiCardActionArea-focusHighlight {
      background-color: rgba(247, 248, 249, 0.6);
      height: ${size === 4 ? '9.875rem' : size === 5 ? '17.375rem' : '29.375rem'};
    }

    .classStyle {
      position: absolute;
      top: 0;
      left: 15px;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0.25rem 1rem 0.125rem;
      color: #fff;
      background-color: #ffa632;
    }

    .mediaFooter {
      position: absolute;
      bottom: 3rem;
      right: 1.5rem;
      display: flex;
    }

    .chipIcon {
      background-color: #fff;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0.125rem;
      border-radius: 50%;
      margin-bottom: 0.25rem;
    }

    .chipTag {
      height: 1.5rem;
      background-color: #fff;
      padding: 0.25rem 0.5rem 0.125rem;
      margin-left: 0.375rem;
      margin-bottom: 0.25rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    .tags {
      position: absolute;
      top: 0.75rem;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      z-index: 2;
    }
  `}
`;

const LgProposal = props => {
  const [hover, setHover] = useState(false);
  const readOnly = process.env.REACT_APP_READ_ONLY;
  const {
    seen,
    proposal,
    proposalClass,
    proposalPublicTags,
    proposalHasNote,
    reactAppUrl,
    size,
    classes,
  } = props;
  const onHover = () => setHover(true);
  const onUnHover = () => setHover(false);
  const thumbnailUrl = size >= 5 ? proposal.large_thumbnail_url : proposal.thumbnail_url;

  return (
    <StyledCard
      onMouseEnter={onHover}
      onMouseLeave={onUnHover}
      seen={seen ? 'true' : 'false'}
      size={size}
    >
      <CardActionArea className="actionArea">
        <CardMedia
          className="cardMedia"
          component="img"
          height="156"
          image={reactAppUrl + thumbnailUrl}
        />
        <CardContent className="py-1 px-3">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">{proposal.number}</h2>
            <h2 className="mb-0 sm">{proposal.name}</h2>
            {!readOnly && (
              <div className="sm">
                {seen && !readOnly
                  ? `Last seen ${moment(new Date(seen)).format('D.MM.YYYY')}`
                  : readOnly
                  ? ''
                  : 'Unseen'}
              </div>
            )}
            {proposalClass && (
              <div
                className="classStyle"
                style={{ backgroundColor: proposalClass.colour }}
              >
                {proposalClass.class}
              </div>
            )}
            <div className="tags">
              {hover && proposalPublicTags
                ? proposalPublicTags.map((tag, i) => {
                    if (i < 4) {
                      return <Chip key={tag.id} label={tag.name} className="chipTag" />;
                    }
                    return null;
                  })
                : null}
            </div>
            <div className="mediaFooter">
              {!readOnly && proposalHasNote && (
                <NotesIcon className="chipIcon" fontSize="small" />
              )}
              {!hover && proposalPublicTags && !readOnly && (
                <Chip label={proposalPublicTags.length + ' tags'} className="chipTag" />
              )}
              {!readOnly && (
                <>
                  <div className={classes.tags}>
                    {hover && proposalPublicTags
                      ? proposalPublicTags.map((tag, i) => {
                          if (i < 4) {
                            return (
                              <Chip
                                key={tag.id}
                                label={tag.name}
                                classes={{
                                  root: classes.chipTag,
                                  label: 'xs p-0',
                                }}
                              />
                            );
                          }
                          return null;
                        })
                      : null}
                  </div>
                  <div className={classes.mediaFooter}>
                    {proposalHasNote && (
                      <NotesIcon className={classes.chipIcon} fontSize="small" />
                    )}
                    {!hover && proposalPublicTags && (
                      <Chip
                        label={proposalPublicTags.length + ' tags'}
                        classes={{
                          root: classes.chipTag,
                          label: 'sm p-0',
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

LgProposal.propTypes = {
  seen: PropTypes.bool.isRequired,
  proposal: PropTypes.object.isRequired,
  proposalClass: PropTypes.object,
  reactAppUrl: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  proposalPublicTags: PropTypes.array.isRequired,
  proposalHasNote: PropTypes.bool.isRequired,
};

LgProposal.defaultProps = {
  proposalClass: {},
};

const mapStateToProps = state => {
  return {
    reactAppUrl: state.proposals.envValues.reactAppUrl,
  };
};

export default connect(mapStateToProps)(LgProposal);
